@font-face {
    font-family: 'Gotu';
    src: local('Gotu'), url(./assets/fonts/Gotu-Regular.ttf) format('truetype');
}


* {
    margin: 0;
    padding: 0;
}
  
/* 
figure out how to set font size constants  
small: 12px
medium: 16px
large: 24px;
xl: 32px;

add colors
*/